import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "OUR SERVICES",
    paragraph:
      "We work with enterprises, organizations, and businesses from various industries, and help them to create and launch products that are used by thousands of customers around the world, by using latest technologies.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div>
            <SectionHeader data={sectionHeader} className="center-content" />
          </div>
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/website-dev.jpg")}
                      alt="Features tile icon 01"
                      // width={30}
                      className="web-img"
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Web Development</h4>
                  <p className="m-0 text-sm">
                    TechArtist offers one of the best website development in the
                    indusrty. We have experties and experience in developing
                    websites across industries.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/mobile-dev.png")}
                      alt="Features tile icon 02"
                      className="web-img"
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Mobile Development</h4>
                  <p className="m-0 text-sm">
                    We develop user-friendly mobile applications for business
                    that elevate their perfomance and gives them a sustainable
                    competitive edge.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/cloud-dev.png")}
                      alt="Features tile icon 03"
                      className="web-img"
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Devops</h4>
                  <p className="m-0 text-sm">
                    We build, test and maintain the infrastructure and tools to
                    allow for the speedy development and release of software.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/blockchain-dev.png")}
                      alt="Features tile icon 05"
                      className="web-img"
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Blockchain Development</h4>
                  <p className="m-0 text-sm">
                    We offer blockchain development for business and large
                    enterprises to safeguard their transactions and bring more
                    clarity and transparency to their business transactions.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/software-dev.png")}
                      alt="Features tile icon 06"
                      className="web-img"
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Software Solutions</h4>
                  <p className="m-0 text-sm">
                    We provide custom Software Solutions, including software
                    product development, software support, maintenance, and
                    testing. The proucts we create go through a rigorous testing
                    ohase to ensure perfect functionality. They help increase
                    the productivity of your employees.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
