import React from "react";
import classNames from "classnames";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
const FooterSocial = ({ className, ...props }) => {
  const classes = classNames("footer-social", className);

  return (
    <div {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <a href="https://www.facebook.com/techartist1">
            <FacebookIcon />
          </a>
        </li>

        <li>
          <a href="https://www.linkedin.com/company/techartist-labs/">
            <LinkedInIcon />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FooterSocial;
